import React, { useEffect, useState } from 'react';

import { useNavigate, Link, useLocation } from 'react-router-dom';
import ReactMapGL, { Marker } from 'react-map-gl';

import Loader from '../components/Loader';
import ElectionBox from '../components/EelectionBox';
import { useTranslation } from 'react-i18next';
import '../languages/i18n';

import API from '../services/ApiLists';
import { ApiCall } from '../services/ApiCall';

import blackpin from '../images/black.png';
import yellowpin from '../images/yellow.png';
import vote from '../images/vote-ico.svg';
import yellow from '../images/yellow-arrow.svg';
import filter from '../images/filter-ico.svg';
import close from '../images/close-x.svg';

import {
  getUserData,
  getUserLatitude,
  getUserLongitude,
  storeUserLatitude,
  storeUserLongitude,
  getBoundsForPoints,
} from '../Functions/Functions';

// import ReactSimplyCarousel from "react-simply-carousel";
import { Carousel } from 'react-responsive-carousel';
import * as geolib from 'geolib';
import BusinessBox from '../components/BusinessBox';

export default function BusinessSeeMore() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [categories, setCategories] = useState();
  const [selectCategories, setSelectCategories] = useState();
  const [startSoon, setStartSoon] = useState();
  const [startSoonFilter, setStartSoonFilter] = useState();
  const [loader, setLoader] = useState(false);
  const [started, setStarted] = useState(true);
  const [notStarted, setNntStarted] = useState(true);
  const [shipped, setShipped] = useState(true);
  const [delivery, setDelivery] = useState(true);
  const [selfCollection, setSelfCollection] = useState(true);
  const [menuShowCategory, setMenuShowCategory] = useState(false);
  const [searchShow, setsearchShow] = useState(false);
  const [menuShow, setMenuShow] = useState(false);
  const [viewport, setViewport] = React.useState({
    latitude: 30.3753,
    longitude: 69.3451,
    zoom: 10,
  });
  const [businessPlaces, setbusinessPlaces] = useState([]);

  const [isfilter, setIsFilter] = useState('');
  const [position, setPosition] = useState();
  const [latitude, setLatitude] = useState('34.0151');
  const [longitude, setLongitude] = useState('71.5249');
  const [user, setUser] = useState();
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [locationID, setLocationID] = useState();

  const [electionStatus, setElectionStatus] = useState(['started', 'not_started']);
  const [deliveryOption, setDeliveryOption] = useState(['1', '2', '3']);
  const [justAdded, setJustAdded] = useState();
  const [show, setshow] = useState(true);
  useEffect(async () => {
    setLoader(true);
    const userData = await getUserData();
    if (userData) {
      setUser(userData);
      getGeoInfo(userData);
      CateforyList(userData);
    }
  }, []);

  function BestBuisnessplacesList(user, latitude, longitude) {
    console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');
    console.log(user);
    console.log(latitude);
    console.log(longitude);
    console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');
    var formData = new FormData();
    formData.append('user_latitude', `${latitude}`);
    formData.append('user_longitude', `${longitude}`);
    formData.append('user_id', user?.user_id);
    formData.append('batch_number', 0);
    formData.append('list_ids', '0');
    console.log('formData', formData);
    ApiCall('Post', API.HomebestShopApi, formData, {
      Authorization: `Bearer ` + user.access_token,
      Accept: 'application/json',
    })
      .catch((error) => {
        console.log('erorr reponse', error);
        setLoader(false);
        //   reject(error.response);
      })
      .then((resp) => {
        console.log('business', resp?.data);
        setLoader(false);
        setbusinessPlaces(resp?.data?.data);
      });
  }

  function CateforyList(user) {
    var formData = new FormData();

    ApiCall('Post', API.categoryListApi, formData, {
      Authorization: `Bearer ` + user.access_token,
      Accept: 'application/json',
    })
      .catch((error) => {
        console.log('erorr reponse', error);
      })
      .then((resp) => {
        setCategories(resp.data.data);
        // console.log("resp.data.data catgeiurs resp.data.data", resp.data.data);
      });
  }
  const getGeoInfo = async (userData) => {
    const userlongitude = await getUserLongitude();
    const userlatitude = await getUserLatitude();
    setLongitude(userlongitude);
    setLatitude(userlatitude);

    setViewport({
      latitude: userlatitude,
      longitude: userlongitude,

      // height: 910,
      // width: 700,
      // maxPitch: 85,
      // // maxZoom: 4,
      // minPitch: 0,
      // minZoom: 0,
      // pitch: 40,
      // transitionDuration: 200,
      // transitionInterruption: 1,
      zoom: 10,
    });
    BestBuisnessplacesList(userData, userlatitude, userlongitude);
  };

  function HomeFtn() {
    setLoader(true);
    StartSoonList(user, latitude, longitude);
    JustAddedList(user, latitude, longitude);
    BestBuisnessplacesList(user, latitude, longitude);
  }

  function JustAddedList(user, latitude, longitude) {
    var formData = new FormData();
    formData.append('user_latitude', `${latitude}`);
    formData.append('user_longitude', `${longitude}`);
    formData.append('user_id', user?.user_id);

    formData.append('batch_number', 0);
    formData.append('list_ids', '0');
    console.log('formData', formData);
    ApiCall('Post', API.HomejustAddedApi, formData, {
      Authorization: `Bearer ` + user.access_token,
      Accept: 'application/json',
    })
      .catch((error) => {
        console.log('erorr reponse', error);
        //   reject(error.response);
        setLoader(false);
      })
      .then((resp) => {
        // console.log(resp.data);
        setLoader(false);
        setJustAdded(resp.data.data);
      });
  }

  function userCurrentLocationStartSoonFtn() {
    setsearchShow(false);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        // console.log(
        //   "user current location reload elections or shops and reset the view on user",
        //   position
        // );
        storeUserLatitude(position.coords.latitude);
        storeUserLongitude(position.coords.longitude);
        setViewport({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          zoom: 12,
        });
      });
    }
  }

  function StartSoonList(user, latitude, longitude) {
    var formData = new FormData();
    formData.append('user_latitude', `${latitude}`);
    formData.append('user_longitude', `${longitude}`);
    formData.append('user_id', user?.user_id);
    formData.append('batch_number', 0);
    formData.append('list_ids', '0');
    console.log('formData', formData);
    ApiCall('Post', API.HomestartingSoonApi, formData, {
      Authorization: `Bearer ` + user.access_token,
      Accept: 'application/json',
    })
      .catch((error) => {
        console.log('erorr reponse', error);
        setLoader(false);
        //   reject(error.response);
      })
      .then((resp) => {
        setLoader(false);
        // console.log("startingsoon", resp.data);
        setStartSoon(resp.data.data);
      });
  }

  function Filter(text) {
    console.log(text);
    if (text == 'Not Started' || text == 'Started') {
      const newData = startSoonFilter.filter((item) => {
        const itemData = `${item.election_status.toUpperCase()}`;
        const textData = text.toUpperCase();

        return itemData != textData && item;
      });
      setStartSoon(newData);
    } else {
      setStartSoon(startSoonFilter);
    }
    // if (text == "Started" && notStarted) {
    //   setNntStarted(false);
    // }
    // if (text == "Started" && value == notStarted) {
    //   setStartSoon(startSoonFilter);
    // } else if (text == "Not Started" && value == started) {
    //   setStartSoon(startSoonFilter);
    // } else {
    //   const newData = startSoonFilter.filter((item) => {
    //     const itemData = `${item.election_status.toUpperCase()}`;
    //     const textData = text.toUpperCase();

    //     return !value
    //       ? itemData == textData && item
    //       : itemData != textData && item;
    //   });

    //   if (value && newData.length == 0) {
    //     setStartSoon(startSoonFilter);
    //   } else {
    //     setStartSoon(newData);
    //   }
    // }
  }
  // console.log("viewport?.latitude", viewport, longitude, latitude);
  return (
    <div class="container-fluid">
      <section class="content-sec row mt-0 pb-0">
        {loader && <Loader />}
        <div class="map-wrap fullscreen">
          {businessPlaces.length > 0 && viewport?.longitude && (
            <ReactMapGL
              mapStyle="mapbox://styles/mapbox/streets-v10"
              mapboxApiAccessToken="pk.eyJ1IjoieGFpbmlraGFuMjAiLCJhIjoiY2tkdmswZjU5MXU4YjJ3cGJkYmpleGhnciJ9.Hn_L5hXdjR4zALA01O_aqQ"
              {...viewport}
              width="100%"
              height="100%"
              style={{ padding: 0 }}
              onTouchMove={() => setsearchShow(true)}
              onViewportChange={(viewport) => {
                setViewport(viewport);
                // setsearchShow(true);
                // console.log("viewport changex", viewport);
              }}
            >
              <Marker
                anchor="center"
                latitude={parseFloat(latitude ? latitude : 30.3753)}
                longitude={parseFloat(longitude ? longitude : 69.3451)}
                draggable
                className="user-icon-marker"
              >
                <button class="btn btn-link">
                  <img
                    class="img-fluid user-map-img"
                    src={user?.picture ? user?.picture : 'images/VFAlien.png'}
                    width="30px"
                    height="30px"
                    alt=""
                  />
                </button>
              </Marker>
              <Marker
                anchor="center"
                latitude={parseFloat(33.3753)}
                longitude={parseFloat(70.3451)}
                draggable
                className="user-icon-marker"
              >
                <button class="btn btn-link">
                  <img
                    class="img-fluid user-map-img"
                    src={user?.picture ? user?.picture : 'images/VFAlien.png'}
                    width="30px"
                    height="30px"
                    alt=""
                  />
                </button>
              </Marker>
              {businessPlaces?.map((item, index) => {
                return (
                  <Marker
                    anchor="center"
                    index={index}
                    latitude={parseFloat(item.latitude)}
                    longitude={parseFloat(item.longitude)}
                    className="election-pin-marker"
                  >
                    <button
                      class="btn btn-link"
                      onClick={() =>
                        user?.login_as === 'visitor'
                          ? navigate(`/businessDetailVisitor/${item?.business_id}`)
                          : navigate(`/businessDetail`, {
                              state: {
                                business: item,
                              },
                            })
                      }
                    >
                     <img
                    class="img-fluid user-map-img"
                    src={user?.picture ? user?.picture : 'images/VFAlien.png'}
                    width="30px"
                    height="30px"
                    alt=""
                  />
                    </button>
                  </Marker>
                );
              })}
            </ReactMapGL>
          )}
          <div class="map-head">
            <Link class="btn btn-back" to={'/home'}>
              <img src={yellow} alt="ico" />
            </Link>
          </div>
        </div>

        <div class="product-wrap overlap">
          <div className="d-flex justify-content-between">
            <button class="btn gps-btn my-2" onClick={() => userCurrentLocationStartSoonFtn()}>
              <img class="img-fluid" src={'images/gps-ico.svg'} alt="ico" />
            </button>
          </div>
          <button class="btn btn-sh" onClick={() => setshow(!show)}>
            {show ? t('Buttons.Hide') : t('Buttons.Show')}
          </button>

          <div class="row">
            <div class="product-list pb-4">
              {show ? (
                loader ? (
                  <Loader />
                ) : (
                  <div class="product-overflow">
                    {businessPlaces?.map((item, index) => {
                      console.log("item",item);
                      
                      return (
                        <BusinessBox
                          item={item}
                          index={index}
                          user={user && user}
                          loader={loader}
                          setLoader={setLoader}
                          HomeFtn={HomeFtn}
                        />
                      );
                    })}
                  </div>
                )
              ) : (
                <div />
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
