import axios from "axios";
import React, { useEffect, useState, useTransition } from 'react';


export default function TermCondition() {
    const { t } = useTransition();

const [termsContent, setTermsContent] = useState('');

useEffect(() => {
  getTermsData();
}, []);

async function getTermsData() {
    const res = await axios.get('https://ipinfo.io/json/');
    fetch('https://test.api.voteandfun.com/api/terms-conditions?region=' + res.data.country, {
      method: 'GET',
    })
      .then((res) => res.json())
      .then((res) => {
        console.log('res getPrivacyData', res);
        if (res.success) {
          setTermsContent(res.data.content);
        }
      })
      .catch((err) => {
        console.log('error', err);
      });
  }

return (
    <div>
 <div
            style={{
              backgroundColor: '#F7FAFC', // White background
              overflowY: 'auto', // Enable vertical scrolling
              padding: '16px', // Optional padding
              boxSizing: 'border-box', // Ensure padding is included in the height calculation
              marginBottom: 15,
            }}
            dangerouslySetInnerHTML={{ __html: termsContent }}
          />
    </div>
)

}