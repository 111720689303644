import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import QR from "../images/qr-ico.svg";
import acc from "../images/my-acc-ico.svg";
import favbtn from "../images/fav-btm-ico.svg";
import locationicon from "../images/location-ico.svg";
import friends from "../images/friends-ico.svg";
import fun from "../images/fun-ico.svg";
import { useTranslation } from "react-i18next";
import "../languages/i18n";

export default function Footer({ user }) {
  const location = useLocation();
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);

  const handleLoginClick = () => {
    // Logic to handle login (e.g., redirecting to login page)
    setModalVisible(false); // Hide modal after login
  };

  const openModal = () => {
    setModalVisible(true);
  };

  return (
    <>
      {modalVisible && (
        <div className="modal reg-modal bg-blur" id="login-message" style={{ display: "block" ,backgroundColor : 'rgba(222, 223, 222 , 0.9)'}}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content minh-unset">
              <div className="alert-bubble-img">
                <img
                  className="img-fluid"
                  src="images/alert-msg-bubble.png"
                  alt="ico"
                />
                <div className="cont py-3">
                  <h5>
                    {t("alerts.Hi!")} <br />
                    {t("alerts.you are still visitor")}
                  </h5>
                  <h5 className="dark">{t("alerts.Click to log-in!!!")}</h5>
                </div>
              </div>
              <div className="button-btm-sec">
                <Link
                  className="btn btn-yellow text-uppercase w-100"
                  to="/login"
                  onClick={handleLoginClick}
                >
                  {t("Buttons.Log-in")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
      <footer>
        {user?.login_as === "visitor" && (
          <Link className="ftr-top" to="/login">
            <img className="fun-ico" src={fun} alt="" />
            <p>{t("Footer.title")}</p>
            <button className="btn">{t("Footer.LOG-IN")}</button>
          </Link>
        )}
        <div className="footer-btm">
          <div className="ftr-btn">
            {user?.login_as === "visitor" ? (
              <div onClick={openModal} className="link">
                <span className="ico-blk">
                  <img src={acc} alt="" />
                </span>
                <p>{t("Footer.My Account")}</p>
              </div>
            ) : (
              <Link
                to="/UserProfile"
                className={`link ${location.pathname === "/UserProfile" ? "active" : ""}`}
              >
                <span className="ico-blk">
                  <img src={acc} alt="" />
                </span>
                <p>{t("Footer.My Account")}</p>
              </Link>
            )}
            <Link
              to="/UserFavourite"
              className={`link ${location.pathname === "/UserFavourite" ? "active" : ""}`}
            >
              <span className="ico-blk">
                <img src={favbtn} alt="" />
              </span>
              <p>{t("Footer.Favorites")}</p>
            </Link>
          </div>
          <div className="ftr-btn">
            <Link
              to="/homeMap"
              className={`link ${location.pathname === "/homeMap" ? "active" : ""}`}
            >
              <span className="ico-blk">
                <img src={locationicon} alt="" />
              </span>
              <p>{t("Footer.By Location")}</p>
            </Link>
            {user?.login_as === "visitor" ? (
              <div onClick={openModal} className="link">
                <span className="ico-blk">
                  <img src={friends} alt="" />
                </span>
                <p>{t("Footer.Friends")}</p>
              </div>
            ) : (
              <Link
                to="/UserFriendList"
                className={`link ${location.pathname === "/UserFriendList" ? "active" : ""}`}
              >
                <span className="ico-blk">
                  <img src={friends} alt="" />
                </span>
                <p>{t("Footer.Friends")}</p>
              </Link>
            )}
          </div>
          <Link to="/Scan" className="btn btn-scan">
            <span>
              <img className="img-fluid" src={QR} alt="" />
              {t("Footer.Scan")}
            </span>
          </Link>
        </div>
      </footer>
    </>
  );
}
